import React from "react";
import {
	Hero,
	NewseLatter,
	OurLocation
} from "../../components/component";
import Meta from "../../components/Meta";

const Home_1 = ({ user }) => {
  return (
    <main>
      <Meta title="Bondgor Cargo" />
      <Hero user={user} />
      <OurLocation />
      {/* <NewseLatter /> */}
    </main>
  );
};

export default Home_1;
