import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home_1 from "./home/home_1";
import { configFetch } from "../requests/config";
import axios from 'axios';
export default function Home({ user }) {
  const { scrollRef } = useContext(UserContext);
  
  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  return (
    <div>
      <Home_1 user={user} />
    </div>
  );
}


export const getServerSideProps = async (context) => {
  const session = await configFetch({ cookie: context.req.cookies.authorization, method: "GET" });
  const config = {
    method: 'get',
    url: `${session.url}/auth/`,
    headers: session.headers
  };
  try {
    const data = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return null;
      });
    return { props: { user: data } };
  } catch (error) {
    console.log(error);
    return { props: { error: true } };
  }
};

